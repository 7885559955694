import "./Header.css";
import React from "react";
import { Button } from "react-bootstrap";

const Header = (props) => {
  return (
    <header className="header">
      <h1>Siren Support Tools Dashboard</h1>
      {props.isLoggedIn === true ? (
        <div className="logout-div">
          <Button
            variant="dark"
            type="button"
            onClick={() => {
              props.deleteCookie();
            }}
          >
            Log out
          </Button>
        </div>
      ) : (
        <div></div>
      )}
    </header>
  );
};

export default Header;
