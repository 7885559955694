import "./App.css";
import "../index.css";
import React, { useState } from "react";

import Header from "../header/Header";
import { useCookies } from "react-cookie";
import Login from "../login/Login";

import DataForm from "../dataForm/DataForm.js";
function App() {
  let [isLoggedIn, setIsLoggedIn] = useState(false);

  let [cookies, setCookie, removeCookie] = useCookies(["std"]);
  const addCookie = () => {
    setCookie("std", process.env.REACT_APP_COOKIES_KEY, {
      maxAge: 600, //age in seconds
      path: "/",
      secure: true,
    });
  };

  const deleteCookie = () => {
    removeCookie("std");
    setIsLoggedIn(false);
  };
  const stdCookie = cookies.std;
  console.log(stdCookie);
  if (stdCookie === process.env.REACT_APP_COOKIES_KEY && isLoggedIn === false) {
    setIsLoggedIn(true);
  }

  return (
    <div className="App">
      <Header deleteCookie={deleteCookie} isLoggedIn={isLoggedIn} />
      {isLoggedIn === false ? (
        <Login setIsLoggedIn={setIsLoggedIn} addCookie={addCookie} />
      ) : (
        <DataForm cookies={cookies} />
      )}
    </div>
  );
}

export default App;
