import React, { useState } from "react";
import { Container, Row, Col, Dropdown, Form, Button } from "react-bootstrap";
import JSONPretty from "react-json-pretty";

import "./DataForm.css";
import Config from "../config.json";

const DataForm = (props) => {
  //array of data types from config file (User, Customer, Device, etc.)
  let [dataType, setDataType] = useState("");
  let [dataTypeChoices, setDataTypeChoices] = useState([]);
  let [manipType, setManipType] = useState("");
  let [manipTypeChoices, setManipTypeChoices] = useState([]);
  let [source, setSource] = useState("");
  let [sourceChoices, setSourceChoices] = useState([]);
  let [name, setName] = useState("");
  let [nameChoices, setNameChoices] = useState([]);
  let [query, setQuery] = useState("");
  let [queryOptions, setQueryOptions] = useState([]);
  let [paramOptions, setParamOptions] = useState([]);
  let [body, setBody] = useState({});
  let [reqUrl, setReqUrl] = useState("");
  let [reqRes, setReqRes] = useState({});

  if (dataTypeChoices.length === 0) {
    const configDataTypes = Config.start.dataTypes;
    const dataTypes = configDataTypes.map((type) => {
      return type.dataType;
    });
    setDataTypeChoices(dataTypes);
  }
  //jsx for data type dropdown
  const dataTypeDropdown = dataTypeChoices.map((choice) => (
    <Dropdown.Item
      key={choice.replaceAll(" ", "-")}
      onClick={() => {
        if (dataType === "" || dataType !== choice) {
          setDataType(choice);
          if (manipType !== "") {
            setManipType("");
          }
          if (manipTypeChoices.length > 0) {
            setManipTypeChoices([]);
          }
          if (source !== "") {
            setSource("");
          }
          if (sourceChoices.length > 0) {
            setSourceChoices([]);
          }
          if (name !== "") {
            setName("");
          }
          if (sourceChoices.length > 0) {
            setNameChoices([]);
          }
          if (query !== "") {
            setQuery("");
          }
          if (queryOptions.length > 0) {
            setQueryOptions([]);
          }
          if (paramOptions.length > 0) {
            setParamOptions([]);
          }
          if (Object.keys(body) > 0) {
            setBody({});
          }
          if (reqUrl !== "") {
            setReqUrl("");
          }
          if (Object.keys(reqRes).length > 0) {
            setReqRes({});
          }
        }
      }}
    >
      {choice}
    </Dropdown.Item>
  ));

  if (dataType !== "" && manipTypeChoices.length === 0) {
    const configDataTypes = Config.start.dataTypes;
    configDataTypes.forEach((data) => {
      if (data.dataType === dataType) {
        const dataManipTypes = data.manipTypes;
        const manipTypes = dataManipTypes.map((manip) => {
          return manip.manipType;
        });
        setManipTypeChoices(manipTypes);
      }
    });
  }
  const manipTypeDropdown = manipTypeChoices.map((choice) => (
    <Dropdown.Item
      key={choice.replaceAll(" ", "-")}
      onClick={() => {
        if (manipType === "" || manipType !== choice) {
          const configDataTypes = Config.start.dataTypes;
          configDataTypes.forEach((data) => {
            const dataManipTypes = data.manipTypes;
            dataManipTypes.forEach((manip) => {
              if (manip.manipType === choice) {
                setManipType(choice);
                if (source !== "") {
                  setSource("");
                }
                if (sourceChoices.length > 0) {
                  setSourceChoices([]);
                }
                if (name !== "") {
                  setName("");
                }
                if (sourceChoices.length > 0) {
                  setNameChoices([]);
                }
                if (query !== "") {
                  setQuery("");
                }
                if (queryOptions.length > 0) {
                  setQueryOptions([]);
                }
                if (paramOptions.length > 0) {
                  setParamOptions([]);
                }
                if (Object.keys(body) > 0) {
                  setBody({});
                }
                if (reqUrl !== "") {
                  setReqUrl("");
                }
                if (Object.keys(reqRes).length > 0) {
                  setReqRes({});
                }
              }
            });
          });
        }
      }}
    >
      {choice}
    </Dropdown.Item>
  ));

  if (dataType !== "" && manipType !== "" && sourceChoices.length === 0) {
    const configDataTypes = Config.start.dataTypes;
    configDataTypes.forEach((data) => {
      if (data.dataType === dataType) {
        const dataManipTypes = data.manipTypes;
        dataManipTypes.forEach((manip) => {
          if (manip.manipType === manipType) {
            const manipSources = manip.sources;
            const sources = manipSources.map((source) => {
              return source.source;
            });
            setSourceChoices(sources);
          }
        });
      }
    });
  }
  let manipTypeCol;

  if (manipTypeChoices.length > 0) {
    manipTypeCol = (
      <Col xs={3}>
        <Dropdown>
          <Dropdown.Toggle variant="dark" id="dropdown-manipType">
            Choose Data Manipulation
          </Dropdown.Toggle>

          <Dropdown.Menu>{manipTypeDropdown}</Dropdown.Menu>
        </Dropdown>
      </Col>
    );
  }
  const sourceDropdown = sourceChoices.map((choice) => (
    <Dropdown.Item
      key={choice.replaceAll(" ", "-")}
      onClick={() => {
        if (source === "" || source !== choice) {
          const configDataTypes = Config.start.dataTypes;
          configDataTypes.forEach((data) => {
            const dataManipTypes = data.manipTypes;
            dataManipTypes.forEach((manip) => {
              if (manip.manipType === manipType) {
                const manipSources = manip.sources;
                manipSources.forEach((sourceType) => {
                  if (sourceType.source === choice) {
                    setSource(choice);
                    if (name !== "") {
                      setName("");
                    }
                    if (sourceChoices.length > 0) {
                      setNameChoices([]);
                    }

                    if (query !== "") {
                      setQuery("");
                    }
                    if (queryOptions.length > 0) {
                      setQueryOptions([]);
                    }
                    if (paramOptions.length > 0) {
                      setParamOptions([]);
                    }
                    if (Object.keys(body) > 0) {
                      setBody({});
                    }
                    if (reqUrl !== "") {
                      setReqUrl("");
                    }
                    if (Object.keys(reqRes).length > 0) {
                      setReqRes({});
                    }
                  }
                });
              }
            });
          });
        }
      }}
    >
      {choice}
    </Dropdown.Item>
  ));

  let sourceTypeCol;

  if (sourceChoices.length > 0) {
    sourceTypeCol = (
      <Col xs={3}>
        <Dropdown>
          <Dropdown.Toggle variant="dark" id="dropdown-manipType">
            Choose Source
          </Dropdown.Toggle>

          <Dropdown.Menu>{sourceDropdown}</Dropdown.Menu>
        </Dropdown>
      </Col>
    );
  }

  if (
    dataType !== "" &&
    manipType !== "" &&
    source !== "" &&
    nameChoices.length === 0
  ) {
    const configDataTypes = Config.start.dataTypes;
    configDataTypes.forEach((data) => {
      if (data.dataType === dataType) {
        const dataManipTypes = data.manipTypes;
        dataManipTypes.forEach((manip) => {
          if (manip.manipType === manipType) {
            const manipSources = manip.sources;
            manipSources.forEach((manipSource) => {
              if (manipSource.source === source) {
                const manipNames = manipSource.names;
                const names = manipNames.map((name) => {
                  return name.name;
                });
                setNameChoices(names);
              }
            });
          }
        });
      }
    });
  }
  let nameDropdown;
  if (nameChoices.length > 0) {
    nameDropdown = nameChoices.map((choice) => (
      <Dropdown.Item
        key={choice.replaceAll(" ", "-")}
        onClick={() => {
          resetForm();
          if (name === "" || name !== choice) {
            const configDataTypes = Config.start.dataTypes;

            configDataTypes.forEach((data) => {
              const dataManipTypes = data.manipTypes;
              dataManipTypes.forEach((manip) => {
                if (manip.manipType === manipType) {
                  const manipSources = manip.sources;
                  manipSources.forEach((manipSource) => {
                    if (manipSource.source === source) {
                      const manipNames = manipSource.names;

                      manipNames.forEach((name) => {
                        if (name.name === choice) {
                          setName(choice);
                          setQuery("");

                          setQueryOptions([]);

                          setParamOptions([]);

                          setBody({});

                          setReqUrl("");
                          // if (name.queries) {
                          //
                          //
                          //
                          // }
                        }
                      });
                    }
                  });
                }
              });
            });
          }
        }}
      >
        {choice}
      </Dropdown.Item>
    ));
  }
  let nameCol;

  if (nameChoices.length > 0) {
    nameCol = (
      <Col xs={3}>
        <Dropdown>
          <Dropdown.Toggle variant="dark" id="dropdown-manipType">
            Choose Name
          </Dropdown.Toggle>

          <Dropdown.Menu>{nameDropdown}</Dropdown.Menu>
        </Dropdown>
      </Col>
    );
  }

  if (
    dataType !== "" &&
    manipType !== "" &&
    source !== "" &&
    name !== "" &&
    queryOptions.length === 0
  ) {
    const configDataTypes = Config.start.dataTypes;
    configDataTypes.forEach((data) => {
      if (data.dataType === dataType) {
        const dataManipTypes = data.manipTypes;
        dataManipTypes.forEach((manip) => {
          if (manip.manipType === manipType) {
            const manipSources = manip.sources;
            manipSources.forEach((manipSource) => {
              if (manipSource.source === source) {
                const manipNames = manipSource.names;
                manipNames.forEach((manipName) => {
                  if (manipName.name === name) {
                    if (manipName.queries) {
                      const manipQueryOptions = manipName.queries.map(
                        (query) => {
                          return query.query;
                        }
                      );

                      setQueryOptions(manipQueryOptions);
                    }
                  }
                });
              }
            });
          }
        });
      }
    });
  }
  if (
    dataType !== "" &&
    manipType !== "" &&
    source !== "" &&
    name !== "" &&
    paramOptions.length === 0
  ) {
    const configDataTypes = Config.start.dataTypes;
    configDataTypes.forEach((data) => {
      if (data.dataType === dataType) {
        const dataManipTypes = data.manipTypes;
        dataManipTypes.forEach((manip) => {
          if (manip.manipType === manipType) {
            const manipSources = manip.sources;
            manipSources.forEach((manipSource) => {
              if (manipSource.source === source) {
                const manipNames = manipSource.names;
                manipNames.forEach((manipName) => {
                  if (manipName.name === name) {
                    if (manipName.body) {
                      const manipParamOptions = manipName.body.map((param) => {
                        return param.param;
                      });

                      setParamOptions(manipParamOptions);
                    }
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  const handleQuerySubmit = (event) => {
    event.preventDefault();

    const url = `${process.env.REACT_APP_SM_SYSTEMS_API_URL}/${
      name === "Get User" || name === "Create User" || name === "Delete User"
        ? "users"
        : name === "Get User Devices" ||
          name === "Add Initial Device to User" ||
          name === "Add Additional Device to User"
        ? "users/devices"
        : name === "Get Customer"
        ? "customers"
        : name === "Get Main Device" || name === "Get Main Device with Details"
        ? "devices/main-devices"
        : name === "Get Wireless Sensor" ||
          name === "Get Wireless Sensor with Details"
        ? "devices/sensors"
        : name === "Get Siren Sat" || name === "Get Siren Sat with Details"
        ? "devices/siren-sats"
        : ""
    }?source=${source.toLowerCase()}${query !== "" ? `&${query}` : ""}${
      name === "Add Initial Device to User"
        ? "&type=initial"
        : name === "Add Additional Device to User"
        ? "&type=additional"
        : name === "Get Main Device"
        ? "&withDetails=false"
        : name === "Get Main Device with Details"
        ? "&withDetails=true"
        : name === "Get Wireless Sensor"
        ? "&withDetails=true&type=wireless"
        : name === "Get Wireless Sensor with Details"
        ? "&withDetails=false&type=wireless"
        : name === "Get Siren Sat"
        ? "&withDetails=false"
        : name === "Get Siren Sat with Details"
        ? "&withDetails=true"
        : ""
    }`;
    let options = {
      method:
        manipType === "UPDATE"
          ? "PATCH"
          : manipType === "CREATE"
          ? "POST"
          : manipType,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${process.env.REACT_APP_SM_SYSTEMS_API_KEY}`,
      },
    };
    options = Object.assign(
      options,
      Object.keys(body).length > 0 ? { body: JSON.stringify(body) } : {}
    );

    fetch(url, options)
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        setReqRes(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleReset = () => {
    if (dataType !== "") {
      setDataType("");
    }

    if (manipType !== "") {
      setManipType("");
    }
    if (manipTypeChoices.length > 0) {
      setManipTypeChoices([]);
    }
    if (source !== "") {
      setSource("");
    }
    if (sourceChoices.length > 0) {
      setSourceChoices([]);
    }
    if (name !== "") {
      setName("");
    }
    if (sourceChoices.length > 0) {
      setNameChoices([]);
    }
    if (query !== "") {
      setQuery("");
    }
    if (queryOptions.length > 0) {
      setQueryOptions([]);
    }
    if (paramOptions.length > 0) {
      setParamOptions([]);
    }
    if (Object.keys(body) > 0) {
      setBody({});
    }
    if (reqUrl !== "") {
      setReqUrl("");
    }
    if (Object.keys(reqRes).length > 0) {
      setReqRes({});
    }
  };

  const resetForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
  };
  let queryFormGroups;
  if (queryOptions.length > 0) {
    queryFormGroups = queryOptions.map((option) => (
      <Form.Group
        className="mb-3"
        key={option.replaceAll(" ", "-")}
        controlId={`queryForm${option}`}
      >
        <Form.Label>{option}</Form.Label>
        <Form.Control
          type={
            option.toLowerCase() === "email"
              ? "email"
              : option.toLowerCase() === "password"
              ? "password"
              : "text"
          }
          placeholder={`Enter ${option}`}
          value={
            query !== "" && query.indexOf(option) !== -1
              ? query.replace(option + "=", "")
              : ""
          }
          onChange={(e) => {
            setQuery(`${option}=${e.target.value}`);
          }}
        />
      </Form.Group>
    ));
  }
  //testsupporttools@gmail.com
  let paramFormGroups;
  if (paramOptions.length > 0) {
    paramFormGroups = paramOptions.map((option) => (
      <Form.Group
        className="mb-3"
        key={option.replaceAll(" ", "-")}
        controlId={`queryForm${option}`}
      >
        <Form.Label>{option}</Form.Label>
        <Form.Control
          type={
            option.toLowerCase() === "email"
              ? "email"
              : option.toLowerCase() === "password"
              ? "password"
              : "text"
          }
          placeholder={`Enter ${option}`}
          onChange={(e) => {
            //  setQuery(`${option}=${e.target.value}`);

            body[option] = e.target.value;
            setBody(body);
          }}
        />
      </Form.Group>
    ));
  }

  let queryCol;

  if (queryFormGroups) {
    queryCol = (
      <Col xs={6}>
        <div className="query-title">Query</div>
        <Form className="query-form" onSubmit={handleQuerySubmit}>
          {queryFormGroups}
          <Button variant="dark" type="submit">
            Submit
          </Button>
        </Form>
      </Col>
    );
  }
  let paramCol;
  if (paramFormGroups) {
    paramCol = (
      <Col xs={6}>
        <Form className="query-form" onSubmit={handleQuerySubmit}>
          {paramFormGroups}
          <Button variant="dark" type="submit">
            Submit
          </Button>
        </Form>
      </Col>
    );
  }
  return (
    <Container className="data-details">
      <Row>
        <Col>
          <div className="details">
            <p>
              <strong>Data Type:</strong> {dataType}
            </p>
            <p>
              <strong>Data Manipulation Type: </strong>
              {manipType}
            </p>
            <p>
              <strong>Data Source: </strong>
              {source}
            </p>
            <p>
              <strong>Name: </strong>
              {name}
            </p>
          </div>
        </Col>
        <Col>
          <div className="reset-div">
            <Button variant="dark" type="button" onClick={handleReset}>
              Reset
            </Button>
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={3}>
          <Dropdown>
            <Dropdown.Toggle variant="dark" id="dropdown-dataType">
              Choose Data Type
            </Dropdown.Toggle>

            <Dropdown.Menu>{dataTypeDropdown}</Dropdown.Menu>
          </Dropdown>
        </Col>
        {manipTypeChoices.length > 0 ? manipTypeCol : <div></div>}
        {sourceChoices.length > 0 ? sourceTypeCol : <div></div>}
        {nameChoices.length > 0 ? nameCol : <div></div>}
      </Row>
      <br />
      <Row>
        {queryOptions.length > 0 ? (
          queryCol
        ) : paramOptions.length > 0 ? (
          paramCol
        ) : (
          <div></div>
        )}
        <Col xs={6}>
          {Object.keys(reqRes).length > 0 ? (
            <div>
              <div className="results-title">Results: </div>
              <JSONPretty
                themeClassName="custom-json-pretty"
                data={reqRes}
              ></JSONPretty>
            </div>
          ) : (
            <div></div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default DataForm;
