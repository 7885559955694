import React, { useState } from "react";

import "./Login.css";
import { Form, Button } from "react-bootstrap";
const Login = (props) => {
  // Declare a new state variable, which we'll call "count"

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const handleLogin = (event) => {
    event.preventDefault();
    if (email === "") {
      setErrorMessage("Please enter an email address!");
    } else if (password === "") {
      setErrorMessage("Please enter a password!");
    } else if (email.toLowerCase() !== process.env.REACT_APP_EMAIL) {
      setErrorMessage("Email does not have sufficient permissions!");
    } else if (password !== process.env.REACT_APP_PASSWORD) {
      setErrorMessage("The password is incorrect!");
    } else {
      props.setIsLoggedIn(true);
      props.addCookie();
    }
  };
  return (
    <Form className="login-form" onSubmit={handleLogin}>
      <p className="error">{errorMessage}</p>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </Form.Group>
      <Button variant="dark" type="submit">
        Log In
      </Button>
    </Form>
  );
};

export default Login;
